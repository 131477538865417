import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  getuserguideAction,
  addUserGuideAction,
  editUserGuideAction,
  deleteUserGuideAction
} from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";

const UserGuide = () => {
  const [getassistance_requestlist, setassistance_requestList] = useState({});
  const [userDetails, setuserDetails] = useState({});
  const [adduserDetails, setadduserDetails] = useState({});

  useEffect(() => {
    getAssistance_request();
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setadduserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const getAssistance_request = async () => {
    let res = await getuserguideAction();
    if (res.success) {
      setassistance_requestList(res.data);
    }
  };



  function editPartner(item) {
    setuserDetails(item);
  }

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "heading",
      text: "Heading",
      cell: (item) => {
        return `${item.heading}`;
      },
    },
    {
      key: "heading_en",
      text: "Heading (EN)",
      cell: (item) => {
        return `${item.heading_en}`;
      },
    },
    {
      key: "content",
      text: "content",
      cell: (item) => {
        return `${item.content == null ? "" : item.content}`;
      },
    },
    {
      key: "content_en",
      text: "content (EN)",
      cell: (item) => {
        return `${item.content_en == null ? "" : item.content_en}`;
      },
    },

    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => editPartner(item)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              name={item.id}
              value={item.id}
            >
              Edit
            </button>
            <br />
            <br />
            <button
              type="button"
              onClick={() => DeleteUserGuide(item)}
              className="btn btn-primary btn-sm"
              name={item.id}
              value={item.id}
            >
              Delete
            </button>
          </>
        );
      },
    },
    {
      key: "date",
      text: "Date",
      cell: (item) => {
        return `${moment(item.datetime).format("YYYY-MM-DD")}`;
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  const updatecontent = async (e) => {
    e.preventDefault();
    let res = await editUserGuideAction(userDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName('closeModal')[0].click();
      getAssistance_request();
    } else {
      toast.error(res.msg);
    }
  };

  const addUserGuide = async (e) => {
    e.preventDefault();
    console.log("adduserDetails", adduserDetails);
    let res = await addUserGuideAction(adduserDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName('closeModal')[0].click();
      getAssistance_request();
    } else {
      toast.error(res.msg);
    }
  };

  const DeleteUserGuide = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Assistant Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteUserGuideAction({ id: id });
        if (res.success) {
          getAssistance_request();

          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");

        }
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">User Guide List</h4>
    
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                      >
                        Add
                      </button>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={getassistance_requestlist}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        content
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={updatecontent}>
                      <div className="modal-body">
                        <div className="container">
                        <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Heading
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Title"
                              onChange={inputHandler}
                              name="heading"
                              value={userDetails.heading}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Heading (EN)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput2"
                              placeholder="Enter Title"
                              onChange={inputHandler}
                              name="heading_en"
                              value={userDetails.heading_en}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Content
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              onChange={inputHandler}
                              name="content"
                              value={userDetails.content}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea2"
                              className="form-label"
                            >
                              Content
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea2"
                              rows={3}
                              onChange={inputHandler}
                              name="content_en"
                              value={userDetails.content_en}
                            />
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" className="btn btn-primary">
                              submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Content
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={addUserGuide}>
                      <div className="modal-body">
                        <div className="container">
                        <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Add Heading
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Heading"
                              onChange={inputHandler1}
                              name="heading"
                              value={adduserDetails.heading}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Add Heading (EN)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput2"
                              placeholder="Enter Heading"
                              onChange={inputHandler1}
                              name="heading_en"
                              value={adduserDetails.heading_en}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Add Content
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              onChange={inputHandler1}
                              placeholder="Enter Content"
                              name="content"
                              value={adduserDetails.content}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea2"
                              className="form-label"
                            >
                              Add Content (EN)
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea2"
                              rows={3}
                              onChange={inputHandler1}
                              placeholder="Enter Content"
                              name="content_en"
                              value={adduserDetails.content_en}
                            />
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" className="btn btn-primary">
                              submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default UserGuide;
