import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { getGiftCardsAction } from "../Action/action";
import moment from "moment";

const GiftCards = () => {
  const [getGiftCardlist, setGiftCardList] = useState({});
  useEffect(() => {
    getGiftCards();
  }, []);

  const getGiftCards = async () => {
    let res = await getGiftCardsAction();
    if (res.success) {
      setGiftCardList(res.data);
    }
  };

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      key: "user.id",
      text: "Buyer",
      cell: (item) => {
        return <span>{item.user.email}</span>;
      },
    },
    {
      key: "amount",
      text: "Amount",
      cell: (item) => {
        return <span>{item.amount}</span>;
      },
    },
    {
      key: "status",
      text: "Status",
      cell: (item) => {
        switch (item.status) {
          case "pending":
            return <span className="badge badge-warning">Pending payment</span>;
          case "success":
            return <span className="badge badge-info">Paid</span>;
          case "assigned":
            return <span className="badge badge-success">Assigned to user</span>;
          case "failed":
            return <span className="badge badge-danger">Failed</span>;
          default:
            return <span className="badge badge-danger">{item.status}</span>;
        }
      },
    },
    {
      key: "payment_intent",
      text: "Payment Intent",
      cell: (item) => {
        return <span>{item.payment_intent}</span>;
      },
    },
    {
      key: "phone_number",
      text: "User phone number",
      cell: (item) => {
        return (
          <span>
            {item.country_code}
            {item.phone_number}
          </span>
        );
      },
    },
    {
      key: "createdAt",
      text: "Created At",
      cell: (item) => {
        return <span>{moment(item.createdAt).utcOffset(2).format("DD-MM-YYYY HH:mm:ss")}</span>;
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      csv: true,
    },
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Gift cards List</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                    </div>
                    <div className="box-body">
                      <ReactDatatable config={configForTable} records={getGiftCardlist} columns={columns} />
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-2 fw-light font-size-sm text-danger">* Red Color Indicates Deleted Users</p>
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default GiftCards;
