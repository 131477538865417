import React, { useEffect, useState } from 'react'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getDashboardStatisticsAction } from '../Action/action';

const Dashboard = () => {

  const [statistics, setStatistics] = useState({
    totalUsers:0,
    totalcategory:0,
    totalitems:0, 
  });

    useEffect(() => {
      getDashboardStatistics();
    }, []);

    const getDashboardStatistics = async () => {
        let res = await getDashboardStatisticsAction();
        if (res.success) {
          let data = res.data[0];
          setStatistics((old) => {
            return { ...old, 
              'totalUsers':data.totalUsers,
              'totalcategory':data.totalcategory,
              'totalitems':data.totalitems ,
              'totalattributes':data.totalattributes,
              'totaltransactions':data.totaltransactions, 
              'totalalert_request':data.totalalert_request ,
              'totalnotification':data.totalnotification ,
              'totalassistance_request':data.totalassistance_request,
              'totalExpert':data.totalExpert
            }
          })
        }
    }

    return (

        <>
        <div className="wrapper">
	        {/* <div id="loader"></div> */}
                <Header/>
                <Sidebar/>
                <div className="content-wrapper">
                  <div className="container-full">
                  <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Dashboard</h3>
                                   
                                </div>
                            </div>
                         
                        </div>
                        {/* Content Header (Page header) */}
                    {/* Main content */}
                    <section className="content pt-0">
                      <div className="row">
                        <div className="col-xl-12 col-12">
                          <div className="row">
                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                      <p className="mb-0"><h4>Total Users</h4></p>
                                      <p className="mb-0">
                                      <h5>{statistics.totalUsers}</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total category</h4></p>
                                      <p className="mb-0">
                                      <h5>{statistics.totalcategory}</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Attributes</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalattributes }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Item</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalitems }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Transactions</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totaltransactions }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Alert Request</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalalert_request }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Notification</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalnotification }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Assistance Request</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalassistance_request }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-12">
                              <div className="box">
                                <div className="box-body">
                                  <div className="no-line-chart d-flex align-items-end justify-content-between">
                                    <div>
                                    <p className="mb-0"><h4>Total Expert</h4></p>
                                      <p className="mb-0">
                                      <h5>{ statistics.totalExpert }</h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          
                         
                          
                        </div>
                       
                       
                      </div>
                    </section>
                    {/* /.content */}
                  </div>
                </div>

                <Footer/>
           </div>
        </>


)

}
export default Dashboard;