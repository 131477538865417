import React, { useEffect, useState, useRef } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import { getnotificationAction, addnotificationAction, getUsersListAction, getItemListAction, getUsersEmailListAction, getCategoryListAction } from "../Action/action";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
import { Dialog } from "@blueprintjs/core";
import AsyncSelect from "react-select/async";
import "@blueprintjs/core/lib/css/blueprint.css";

const Notification = () => {
  const [getnotification, setnotification] = useState({});
  const [form, setform] = useState({
    users: [],
    usersToExclude: [],
    itemId: null,
    categoryId: null,
    title: "",
    message: "",
    sendToAll: false,
    sendToUnregistred: false,
    excludeBuyers: false,
    includeBuyers: false,
  });
  const [validatioError, setvalidatioError] = useState({});

  const [itemdetails, setitemdetails] = useState([]);
  const [isDialogOpen, setisDialogOpen] = useState(false);
  const [saving, setsaving] = useState(false);
  const [show] = useState(true);
  const [emailed, setEmailed] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getnotificationlist();
    getusersdetails();
    getitemdetails();
    getcategories();
  }, []);

  const getcategories = async () => {
    let res = await getCategoryListAction();
    if (res) {
      setCategories(res.data);
      console.log(res.data);
    }
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setform({ ...form, [name]: value });
  };

  const handleSelectAllClick = () => {
    setform((prev) => ({ ...prev, sendToAll: !prev.sendToAll, users: [], usersToExclude: [] }));
  };
  const handleSelectUnregistred = () => {
    setform((prev) => ({ ...prev, sendToUnregistred: !prev.sendToUnregistred }));
  };

  const handleSelectExcludeBuyers = () => {
    setform((prev) => ({ ...prev, excludeBuyers: !prev.excludeBuyers, includeBuyers: false }));
  };

  const handleSelectIncludeBuyers = () => {
    setform((prev) => ({ ...prev, includeBuyers: !prev.includeBuyers, excludeBuyers: false }));
  };

  function validate() {
    let titleError = "";
    let messageError = "";

    if (form.title === "") {
      titleError = "Title is required.";
    }
    if (form.message === "") {
      messageError = "Notification message is required.";
    }
    if (titleError || messageError) {
      setvalidatioError({
        titleError,
        messageError,
      });
      return false;
    } else {
      return true;
    }
  }

  const getusersdetails = async () => {
    let res1 = await getUsersEmailListAction();

    if (res1) {
      setEmailed(res1?.data);
    }
  };

  const getitemdetails = async () => {
    let res = await getItemListAction();
    if (res) {
      setitemdetails(res.data);
    }
  };

  const getnotificationlist = async () => {
    let res = await getnotificationAction();
    if (res.success) {
      setnotification(res.data);
    }
  };

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "email",
      text: "Email",
      cell: (item) => {
        return item.email ? `${item.email}` : "-";
      },
    },
    {
      key: "title",
      text: "Title",
      cell: (item) => {
        return `${item.title}`;
      },
    },

    {
      key: "notifcation_message",
      text: "Message",
      cell: (item) => {
        return <textarea rows={3} className="form-control" value={item.notifcation_message} readOnly />;
      },
    },
    {
      key: "datetime",
      text: "Date",
      cell: (item) => {
        return `${moment(item.datetime).format("YYYY-MM-DD")}`;
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
  };

  const addNotificationlist = async (e) => {
    e.preventDefault();
    const isValid = validate();

    if (!isValid) {
      return;
    }
    console.log(form);

    setsaving(true);

    let res = await addnotificationAction(form);

    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}notification`;
      }, 2000);
      getnotificationlist();
    } else {
      toast.error(res.msg);
      setsaving(false);
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Dialog
          title="Please Wait..."
          style={{
            color: "#590ba2",
            textAlign: "center",
          }}
          isOpen={isDialogOpen}
          isCloseButtonShown={false}
        >
          <div className="text-center pl-3 pr-3">
            <br />

            <p style={{ color: "red" }}>Please do not refresh page or close tab.</p>
            <div>
              <div className="spinner-border"></div>
            </div>
          </div>
        </Dialog>
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Notification List</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                      <button
                        type="button"
                        //   onClick={() => editPartner(item)}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        //   name={item.id}
                        //   value={item.id}
                      >
                        Send Notification
                      </button>
                    </div>
                    <div className="box-body">
                      <ReactDatatable config={configForTable} records={getnotification} columns={columns} />
                    </div>
                  </div>
                </div>
              </div>

              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{
                  display: show === true ? "none" : "block",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Send Notification
                      </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <form onSubmit={addNotificationlist}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label className="form-label">Item List</label>
                            <select className="form-control" name="itemId" value={form.itemId} onChange={inputHandler}>
                              <option value={0}>-Select item-</option>
                              {itemdetails.map((item) => (
                                <option value={item.id} selected={item.id === form.itemId}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <div className="mb-3">
                              <label className="form-label mt-3">Users who purchased in category</label>
                              <select className="form-control" name="categoryId" value={form.categoryId} onChange={inputHandler}>
                                <option value="">-Select category-</option>
                                {categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.category_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {!form.sendToAll && (
                            <div className="mb-3 ">
                              <label className="form-label">Users List</label>
                              {/* <div>{selectedOptionsLength}</div> */}
                              <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={async (value) => {
                                  if (value.length > 4) {
                                    let data = emailed
                                      .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                                      .map((item) => ({ value: item.id, label: item.name }));
                                    return data;
                                  } else {
                                    return [];
                                  }
                                }}
                                onChange={(data) => {
                                  setform({ ...form, users: data.map((item) => item.value) });
                                }}
                                defaultOptions
                              />
                            </div>
                          )}
                          {<div className="mb-3">
                            <input type="checkbox" className="me-2" checked={form.sendToAll} onChange={handleSelectAllClick} />
                            Select all registred users
                          </div>}
                          {form.sendToAll && (
                            <div className="mb-3 ">
                              <label className="form-label">Users to exclude</label>
                              {/* <div>{selectedOptionsLength}</div> */}
                              <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={async (value) => {
                                  if (value.length > 4) {
                                    let data = emailed
                                      .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
                                      .map((item) => ({ value: item.id, label: item.name }));
                                    return data;
                                  } else {
                                    return [];
                                  }
                                }}
                                onChange={(data) => {
                                  setform({ ...form, usersToExclude: data.map((item) => item.value) });
                                }}
                                defaultOptions
                              />
                            </div>
                          )}
                          <div className="mb-3">
                            <input type="checkbox" className="me-2" checked={form.sendToUnregistred} onChange={handleSelectUnregistred} />
                            Send to unregistred users
                          </div>

                          {form.sendToAll && !form.categoryId && (
                            <>
                              <div className="mb-3">
                                <input type="checkbox" className="me-2" checked={form.excludeBuyers} onChange={handleSelectExcludeBuyers} />
                                Exclude buyers
                              </div>
                              <div className="mb-3">
                                <input type="checkbox" className="me-2" checked={form.includeBuyers} onChange={handleSelectIncludeBuyers} />
                                Only buyers
                              </div>
                            </>
                          )}
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Title
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter title" onChange={inputHandler} name="title" />
                            <span className="validationErr">{validatioError.titleError}</span>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Enter Message
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter Message" onChange={inputHandler} name="message" />
                            <span className="validationErr">{validatioError.messageError}</span>
                          </div>
                          <div className="modal-footer mt-20">
                            {!saving ? (
                              <button type="submit" className="btn btn-primary">
                                Send
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary" disabled>
                                Please wait <i className="fa fa-spinner fa-spin"></i>
                              </button>
                            )}

                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Notification;
