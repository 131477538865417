import React, { useEffect, useState } from "react";
// import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { getSellOrdersAction } from "../Action/action";
import Swal from "sweetalert2";
import { Toaster } from "react-hot-toast";
import moment from "moment";
// import Web3 from 'web3';
import axios from 'axios';

const SellTrxExternal = () => {
  const [getTransactionlist, setTransactiontList,] = useState({});
  // const [form, setForm] = useState({});

  const [euroInOneMatic, setEuroInOneMatic] = useState(0);

  useEffect(() => {
    getSellOrders();
    getEuroInOneMatic();
  }, []);

  const getSellOrders = async () => {
    let res = await getSellOrdersAction();
    if (res.success) {
      let data = res.data;
      setTransactiontList(data);
    }
  };

  const processingAlert = async () => {
    let timerInterval
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: '<b>Transaction is being process...</b></br><p>Please do not close the window</p>',
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('Transaction done!')
      }
    })
  }

  // const closeProcessingAlert = async () => {
  //   Swal.close()
  // }

  const getEuroInOneMatic = async () => {
    await axios({
      method: 'get',
      url: `https://api.binance.com/api/v3/ticker/price?symbol=MATICEUR`,
    }).then(response => {
      let euroInOneMatic = parseFloat(response.data.price).toFixed(6);
      setEuroInOneMatic(euroInOneMatic);
    })
  }

  const sellSharesExternal = async (item) => {
    processingAlert();
    console.log(item);
    // await getEuroInOneMatic();
    // let sharePriceInMatic = item.euroPricePerShare/euroInOneMatic;
    // let sellerAddress = item.web2_wallet;
    // let share_qty = item.share_qty;
    // let onboarding_address = item.onboarding_address;
    // let share_contract = item.share_contract;

    // if (window.ethereum) {
    //   let web3 = new Web3(window.ethereum);
    //   let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    //   let currentNetwork = await web3.currentProvider.chainId;
    //   web3.eth.defaultAccount = accounts[0];

    //   if (currentNetwork != config.chainId) {
    //     closeProcessingAlert()
    //     toast.error('Please select Polygon smartchain!! ');
    //     return false;
    //   }
    //   try {
    //     let ABI = config.AbiShareContract;
    //     const contract = await new web3.eth.Contract(ABI, share_contract);
    //     let tx_builder = await contract.methods.sellSharesExternal(receiver_address.toString(), share_qty);

    //     let encoded_tx = tx_builder.encodeABI();

    //     let gasPrice = await web3.eth.getGasPrice();
    //     gasPrice = parseInt(gasPrice) + parseInt(10000000000);

    //     let gasLimit = await web3.eth.estimateGas({
    //       gasPrice: web3.utils.toHex(gasPrice),
    //       to: contractAddress,
    //       from: accounts[0],
    //       chainId: config.chainId,
    //       data: encoded_tx,
    //     });


    //     const txData = await web3.eth.sendTransaction({
    //       gasPrice: web3.utils.toHex(gasPrice),
    //       gas: web3.utils.toHex(gasLimit),
    //       to: contractAddress,
    //       from: accounts[0],
    //       chainId: config.chainId,
    //       data: encoded_tx,
    //     });

    //     if (txData.transactionHash) {
    //       form.token_transfer_hash = txData.transactionHash;
    //       form.token_transfer_address = receiver_address;
    //       form.trx_id = item.id;
    //       form.user_id = item.user_id;
    //       let res = await updateTransactionAction(form);
    //       if (res.success) {
    //         getSellOrders();
    //         toast.success(res.msg);
    //       } else {
    //         toast.error(res.msg);
    //       }
    //     } else {
    //       toast.error('Transaction failed! Plase try again.');
    //     }
    //     closeProcessingAlert();
    //     return;
    //   }
    //   catch (err) {
    //     closeProcessingAlert();
    //     toast.error('Transaction reverted : ' + err.toString().split('execution reverted:')[1]?.toString().split('"')[0])
    //     return false;
    //   }
    // }
    // else {
    //   closeProcessingAlert();
    //   toast.error('Please connect your Metamask wallet!');
    //   return;
    // }
  };

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "username",
      text: "UserName",
      cell: (item) => {
        return `${item.username}`;
      },
    },
    {
      key: "action",
      text: "Details",
      cell: (item) => {
        return (
          <>
            <span>Item Name: {item.item_name} </span><br />
            <span>Price: € {item.euroPricePerShare} </span><br />
            <span>Share Qty: {item.share_qty} </span><br />
          </>
        );
      }
    },

    {
      key: "Date",
      text: "Date",
      cell: (item) => {
        return (
          <>
            <b>Date: </b> {moment(item.datetime).format("YYYY-MM-DD")}
          </>
        )
      }
    },
    {
      key: "Action",
      text: "Action",
      cell: (item) => {

        return (
          <>
            {item.status === 0 ?
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => sellSharesExternal(item)}
              >
                Put On Sell
              </button>
              :
              ''
            }
            {item.token_transfer_hash === 1 ?
              <i className="fa fa-check"> </i>
              :
              ''
            }
          </>
        );
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">

            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Transactions List</h4>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={getTransactionlist}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default SellTrxExternal;
