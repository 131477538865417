import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  getRefundslistAction,
} from "../Action/action";
import { Toaster } from "react-hot-toast";

const Refunds = () => {
  const [allrefundlist, setallrefundList] = useState({});
  useEffect(() => {
    getpartner();
  }, []);

  const getpartner = async () => {
    let res = await getRefundslistAction();
    if (res) {
      setallrefundList(res.data);
      console.log(res.data);
    }
  };



  const columns = [
    {
      key: "#",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "user.name",
      text: "Name",
      cell: (item) => {
        return `${item.user.name}`;
      },
    },
    {
      key: "email",
      text: "Email",
      cell: (item) => {
        return `${item.user.email}`;
      },
    },
    {
      key: "Amount",
      text: "Amount",
      cell: (item) => {
        return `€ ${item.amount}`;
      },
    },
    {
      key: "Status",
      text: "Amount",
      cell: (item) => {
        switch (item.paymentstatus) {
          case 'success_refund':
            return 'Success'
            break;
          case 'pending_refund':
            return 'pending'
            break;

          default:
            return "------"
            break;
        }
      },
    },
    {
      key: "Date & time",
      text: "Date & time",
      cell: (item) => {
        return `${item.updatedAt.substring(0, 10)}`;
      },
    },

  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",

  };

  return (
    <>
      <div className="wrapper">
        <Toaster />

        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Refunds</h4>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={allrefundlist}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Refunds;
