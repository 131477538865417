import React from 'react'
const Footer = () => {
  return (
    <>
      {/* /.content-wrapper */}
      <footer className="main-footer">
        <div className="pull-right d-none d-sm-inline-block">

        </div>
        2022 © <a href="#"> Collecto Admin Panel</a>. Restricted Area

      </footer>
    </>
  )
}
export default Footer;