import React, { useEffect, useState, useCallback, useMemo } from "react";
import config from "../coreFIles/config";
import { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  userdeletetAction,
  updatekycapprovalAction,
  rejectkycapprovalAction,
  getUsersLiquidity,
  setUsersLiquidity,
  getUsersV2ListAction,
  getAllUsers,
  getUserDetailsByIdAction,
} from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  createTheme,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import { Edit, Home, Message, Settings } from "@mui/icons-material";
import { TableTheme } from "../components/TableTheme";
import UserTransactionsTable from "../components/UserTransactionsTable";
import UserActionsTable from "../components/UserActionsTable";
import UserWithdrawalsTable from "../components/UserWithdrawalsTable";
import UserDetails from "../components/UserDetails";
import UserReferralsTable from "../components/UserReferralsTable";
import UserItemsTable from "../components/UserItemsTable";

const UserDetail = () => {
  //Liquidity Modal

  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const getUserDetails = async (id) => {
    try {
      setIsLoading(true);
      const response = await getUserDetailsByIdAction(id);
      console.log(response);
      if (!response || response.success !== true) {
        throw new Error("Failed to fetch user details");
      }
      console.log(response.data);
      setUserDetails(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsError(true);

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (id) {
      setUserId(id);
      getUserDetails(id);
    }
  }, []);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={TableTheme}>
          <div className="wrapper">
            {/* <div id="loader"></div> */}
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">User Details {userId} </h4>
                        </div>
                        <div className="box-body">
                          {isLoading ? (
                            <p>Loading user details...</p>
                          ) : userDetails ? (
                            <>
                              <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="user details tabs">
                                    <Tab label="User Details" />
                                    <Tab label="Transactions" />
                                    <Tab label="Actions" />
                                    <Tab label="Withdrawals" />
                                    <Tab label="Referrals" />
                                    <Tab label="Items" />
                                  </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0}>
                                  <UserDetails userDetails={userDetails} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                  <UserTransactionsTable transactions={userDetails.transactions} isLoading={isLoading} isError={isError} isRefetching={isRefetching} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                  <UserActionsTable actions={userDetails.adminActions} isLoading={isLoading} isError={isError} isRefetching={isRefetching} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={3}>
                                  <UserWithdrawalsTable withdrawals={userDetails.withdrawals} isLoading={isLoading} isError={isError} isRefetching={isRefetching} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={4}>
                                  <UserReferralsTable userReferredBy={userDetails.userReferredBy} referrals={userDetails.referrals} isLoading={isLoading} isError={isError} isRefetching={isRefetching} />
                                </TabPanel>
                                <TabPanel value={tabValue} index={5}>
                                  <UserItemsTable itemAdditions={userDetails.itemAdditionsWithCount} isLoading={isLoading} isError={isError} isRefetching={isRefetching} />
                                </TabPanel>
                              </Box>
                            </>
                          ) : (
                            <p>No user details available.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Add Liquidity Modal */}

                  {/*Add Liquidity Modal Ends */}
                </section>
                {/* /.content */}
                {/* /.content */}
              </div>
            </div>

            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};
export default UserDetail;
