import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import { getTransactionListAction, updateTransactionAction } from "../Action/action";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import Web3 from 'web3';

const BuyTrxExternal = () => {
  const [getTransactionlist, setTransactiontList,] = useState({});
  const [form] = useState({});

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    let res = await getTransactionListAction();
    if (res.success) {
      let data = res.data;
      data = data.filter(function (el) {
        return el.payment_method !== 'CRYPTO' && el.trx_type_id === 1
      });
      setTransactiontList(data);
    }
  };

  const processingAlert = async () => {
    let timerInterval
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: '<b>Transaction is being process...</b></br><p>Please do not close the window</p>',
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('Transaction done!')
      }
    })
  }

  const closeProcessingAlert = async () => {
    Swal.close()
  }

  const buySharesWithExternalPayment = async (item) => {
    processingAlert();
    let receiver_address = item.web2_wallet;
    let share_qty = item.share_qty;
    let onboarding_address = item.onboarding_address;
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      let currentNetwork = await web3.currentProvider.chainId;
      web3.eth.defaultAccount = accounts[0];

      if (currentNetwork != config.chainId) {
        closeProcessingAlert()
        toast.error('Please select Polygon smartchain!! ');
        return false;
      }
      try {
        let ABI = config.AbiItemOnboard;
        let contractAddress = onboarding_address;
        const contract = await new web3.eth.Contract(ABI, contractAddress);
        let tx_builder = await contract.methods.buySharesWithExternalPayment(receiver_address.toString(), share_qty);
        let encoded_tx = tx_builder.encodeABI();
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = parseInt(gasPrice) + parseInt(10000000000);
        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: contractAddress,
          from: accounts[0],
          chainId: config.chainId,
          data: encoded_tx,
        });
        const txData = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: contractAddress,
          from: accounts[0],
          chainId: config.chainId,
          data: encoded_tx,
        });

        if (txData.transactionHash) {
          form.token_transfer_hash = txData.transactionHash;
          form.token_transfer_address = receiver_address;
          form.trx_id = item.id;
          form.user_id = item.user_id;
          let res = await updateTransactionAction(form);
          if (res.success) {
            getTransactions();
            toast.success(res.msg);
          } else {
            toast.error(res.msg);
          }
        } else {
          toast.error('Transaction failed! Plase try again.');
        }
        closeProcessingAlert();
        return;
      }
      catch (err) {
        closeProcessingAlert();
        toast.error('Transaction reverted : ' + err.toString().split('execution reverted:')[1]?.toString().split('"')[0])
        return false;
      }
    }
    else {
      closeProcessingAlert();
      toast.error('Please connect your Metamask wallet!');
      return;
    }
  };

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "username",
      text: "UserName",
      cell: (item) => {
        return `${item.username}`;
      },
    },
    {
      key: "action",
      text: "Details",
      cell: (item) => {
        return (
          <>
            <span>Item Name: {item.item_name} </span><br />
            <span>Price: € {item.price}/share </span><br />
            <span>Amount: € {item.amount} </span><br />
            <span>Share Qty: {item.share_qty} </span><br />
          </>
        );
      }
    },

    {
      key: "action",
      text: "Payment Info",
      cell: (item) => {
        let payment_info = JSON.parse(item.payment_info);
        return (
          <>
            <b>Card Holder Name: </b>{payment_info.card_holder_name}<br />
            <b>Card Number: </b>{payment_info.card_number} <br />
            <b>Card Expiry: </b>{payment_info.card_expiry} <br />
            <b>Date: </b> {moment(item.datetime).format("YYYY-MM-DD")}
          </>
        )
      }
    },
    {
      key: "Action",
      text: "Action",
      cell: (item) => {

        return (
          <>
          
            {item.token_transfer_hash == null ?
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => buySharesWithExternalPayment(item)}
              >
                Transfer
              </button>
              :
              ''
            }
            {item.token_transfer_hash != null ?
              <i className="fa fa-check"> Transferred</i>
              :
              ''
            }
          </>
        );
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">

            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Transactions List</h4>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={getTransactionlist}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default BuyTrxExternal;
