import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import { addAttributeAction, getCategoryListAction } from "../Action/action";
import { useParams } from "react-router-dom";
// asdf
const Addattributes = () => {
  const [form, setForm] = useState({});
  const [getcategorylist, setGetCategoryList] = useState([]);
  const [validatioError, setvalidatioError] = useState({});

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  let { category_id } = useParams();

  function validate() {
    let attribute_nameError = "";
    if (form.attribute_name === "") {
      attribute_nameError = "Attribute is required.";
    }

    if (attribute_nameError) {
      setvalidatioError({
        attribute_nameError,
      });
      return false;
    } else {
      return true;
    }
  }
  const submitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await addAttributeAction(form);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}attributes`;
        }, 1200);
      } else {
        toast.error(res.msg);
      }
    }
  };

  useEffect(() => {
    getcategory();
    setForm((old) => {
      return { ...old, 'category_id': category_id };
    });
  }, [category_id])
  
  const getcategory = async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setGetCategoryList(res.data);
    }
  };
  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">


            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Add Attributes</h4>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Select:</label>
                            <select className="form-control" name="category_id" value={form.category_id} onChange={inputHandler} >
                              <option value='' >- Select Category -</option>
                              {getcategorylist.map((item) => (

                                <option value={item.id} selected  >{item.category_name}</option>

                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Attribute Name:</label>
                            <input
                                className="form-control"
                                type="text"
                                name="attribute_name"
                                value={form.attribute_name}
                                onChange={inputHandler}
                                placeholder="Attribute Name"
                              />

                          </div>
                        </div>

                      </div>

                      <div className="text-center">
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={submitForm}
                        >
                          Save
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Addattributes;
