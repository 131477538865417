import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  editAttributeAction,
  getAttributeDetailAction,
  getCategoryListAction,
} from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";

const Attributeupdate = () => {
  const [categorylist, setCategoryList] = useState([]);
  const [form, setForm] = useState({
    id: "",
    attribute_name: "",
    category_id: "",
  });

  //  const [categoryid , setcategoryid] = useState();

  useEffect(() => {
    getCategoryid();
    getcategory();
  }, []);

  const getCategoryid = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getAttributeDetailAction({ id: id });

    if (res.success) {
      // let res1 = await getCategoryDetailAction({ id: res.data.category_id });
      // setcategoryid(res1.data)
      let data = res.data;
      setForm((old) => {
        return {
          ...old,
          id: id,
          attribute_name: data.attribute_name,
          category_id: data.category_id,
        };
      });
    }
  };

  const getcategory = async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setCategoryList(res.data);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let res = await editAttributeAction(form);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}attributes`;
      }, 1200);
    } else {
      toast.error(res.msg);
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Attribute</h4>
                    </div>
                    <div className="row mt-20 mb-50">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                              Category
                            </label>
                            <div className="col-md-12">
                              <select
                                className="form-control"
                                name="category_id"
                                value={form.category_id}
                                onChange={inputHandler}
                              >
                                <option value="">-Select Category-</option>
                                {categorylist.map((item) => (
                                  <option value={item.id}>
                                    {item.category_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <span className="validationErr">
                              {/* {validatioError.category_nameError} */}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-2"></div>

                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                              Attribute Name
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="attribute_name"
                                value={form.attribute_name}
                                onChange={inputHandler}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      
                      <div className="text-center">
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={submitForm}
                        >
                          Save Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Attributeupdate;
