import React, { useEffect, useState, useCallback } from "react";
import config from "../coreFIles/config";
import { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { getReferrals, getUsersLiquidity, setUsersLiquidity, setReferralBonus, getUserActions } from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";

const UserActions = () => {
  const [usersList, setusersList] = useState({});

  //Liquidity Modal
  const [loader, setLoader] = useState(true);

  const getUsersList = useCallback(async () => {
    setLoader(true);
    let res = await getUserActions();
    if (res.success) {
      setLoader(false);
      setusersList(
        res.data.map((item, index) => {
          return {
            ...item,
            Sno: index + 1,
            email: item.user?.email,
            username: item.user?.name,
            phone: item.user?.country_code + " " + item.user?.mobile_no,
            item: item.item?.name,
            date: item.createdAt,
          };
        })
      );
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "email",
      text: "email",
      sortable: true,

      cell: (item) => {
        return item.user?.email;
      },
    },
    {
      key: "username",
      text: "username",
      Hide: true,
      searchable: true,
      cell: (item) => {
        return item.user?.name;
      },
    },
    {
      key: "phone",
      text: "Phone",
      Hide: true,
      cell: (item) => {
        return item.user?.country_code + " " + item.user?.mobile_no;
      },
    },
    {
      key: "action_type",
      text: "Type",
      Hide: true,
      cell: (item) => {
        return item.action_type;
      },
    },
    {
      key: "item",
      text: "Item",
      Hide: true,
      cell: (item) => {
        return item.item;
      },
    },
    {
      key: "createdAt",
      text: "Date",
      Hide: true,
      cell: (item) => {
        return moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss");
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      csv: true,
    },
  };

  const onSort = (text, records, sort) => {
    //String, Object[], String
    console.log(text, records, sort);
    return records;
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Toaster />
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">User actions </h4>
                    </div>
                    <div className="box-body">
                      {!loader ? (
                        <ReactDatatable config={configForTable} records={usersList} columns={columns} onSort={onSort} />
                      ) : (
                        <>
                          <br />
                          <br />
                          <center>
                            <h4>
                              <i className="fa fa-spinner fa-spin"></i> &nbsp; Please wait
                            </h4>
                          </center>
                          <br />
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default UserActions;
