import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import config from "../coreFIles/config";

const UserReferralsTable = ({userReferredBy, referrals, isLoading, isError, isRefetching }) => {
  const table = useMaterialReactTable({
    columns: [
      {
        accessorKey: "id",
        header: "ID",
        size: 20,
      },
      {
        accessorKey: "referrer_id",
        header: "Referrer ID",
        size: 30,
      },
      {
        accessorKey: "user.name",
        header: "Referred User",
        size: 50,
      },
      {
        accessorKey: "user.email",
        header: "Referred Email",
        size: 50,
        accessorFn: (row) => (
          <>
            <a target="_blank" rel="noreferrer" href={`${config.baseUrl}userDetails?id=${row.user.id}`}>{row.user.email}</a>
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 30,
      },
      {
        accessorKey: "created_at",
        header: "Date",
        Cell: ({ cell }) => moment(cell.getValue()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ],
    data: referrals || [],
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      sorting: [{ id: "created_at", desc: true }],
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
  });

  return <div>
    {userReferredBy && userReferredBy.user && (
      <div className="card mb-3" style={{ backgroundColor: '#1d2330', color: '#a2a4a9', border: '1px solid #ffffff1f' }}>
        <div className="card-body">
          <h5 className="card-title" style={{ color: '#ffffff' }}>Referred By</h5>
          <p className="card-text">User ID: {userReferredBy.user.id}</p>
          <p className="card-text">User Name: {userReferredBy.user.name}</p>
          <p className="card-text">User Email: <a href={`${config.baseUrl}userDetails?id=${userReferredBy.user.id}`}>{userReferredBy.user.email}</a></p>
        </div>
      </div>
    )}

    <MaterialReactTable table={table} />
    </div>;
};

export default UserReferralsTable;
