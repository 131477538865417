import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Swal from "sweetalert2";
import { getModalPromo, deleteModalPromo, addModalPromo, getItemListAction, editModalPromo } from "../Action/action";
import moment from "moment/moment";
import Select from "react-select";

const PromoModal = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [form, setForm] = useState({
    previewImage: "./images/noimage.png",
    title: "",
    title_en: "",
    image: "",
    item: "",
    description: "",
    description_en: "",
    button_text: "",
    button_text_en: "",
    start_date: "",
  });
  const [promo, setPromo] = useState({
    previewImage: "./images/noimage.png",
    title: "",
    title_en: "",
    image: "",
    item: "",
    description: "",
    description_en: "",
    button_text: "",
    button_text_en: "",
    start_date: "",
  });

  const [validationError, setValidationError] = useState({});
  const [saving, setSaving] = useState(false);
  const [remove, setRemove] = useState(false);
  const [itemList, setItemList] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    getItemDetail();
  }, []);

  const toggleEditMode = () => setIsDisabled(!isDisabled);

  const getItemDetail = async () => {
    let res = await getModalPromo();
    if (res.success) {
      setIsDisabled(true);
      let data = res.data[0];
      let start_date = moment(data.start_date).format("YYYY-MM-DD");
      setForm((old) => {
        return {
          ...old,
          title: data.title,
          image: data.media,
          previewImage: config.imageUrl + data.media,
          description: data.description,
          button_text: data.button_text,
          item: data.item_id,
          start_date: start_date,
          title_en: data.title_en,
          description_en: data.description_en,
          button_text_en: data.button_text_en,
        };
      });
      setPromo((old) => {
        return {
          ...old,
          title: data.title,
          image: data.media,
          previewImage: config.imageUrl + data.media,
          description: data.description,
          button_text: data.button_text,
          item: data.item_id,
          start_date: start_date,
          title_en: data.title_en,
          description_en: data.description_en,
          button_text_en: data.button_text_en,
        };
      });
      getItems();
    } else {
      getItems();
    }
  };

  const getItems = async () => {
    let res = await getItemListAction();
    if (res.success) {
      let selectList = res.data.map((el) => {
        return {
          value: el.id,
          label: el.name,
          image: config.imageUrl + el.image,
          target: {
            name: "item",
            value: el.id,
          },
        };
      });
      res.data.filter((data) => {
        if (data.id === Number(form.item)) {
          const item = {
            label: data.name,
            image: config.imageUrl + data.image,
          };
          setItem(item);
        }
      });
      setItemList(selectList);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, image: img, previewImage: URL.createObjectURL(img) };
      });
    }
  };

  function validate() {
    let imageError = "";
    let titleError = "";
    let descriptionError = "";
    let textButtonError = "";
    let startDateError = "";
    if (form.title === "") {
      titleError = "Title is required.";
    }
    if (form.image === "") {
      imageError = "Image is required.";
    }
    if (form.description === "") {
      descriptionError = "Description is required.";
    }
    if (form.button_text === "") {
      textButtonError = "Text button is required.";
    }
    if (form.start_date === "") {
      startDateError = "Start date is required.";
    }
    if (form.title_en === "") {
      titleError = "Title (EN) is required.";
    }
    if (form.description_en === "") {
      descriptionError = "Description (EN) is required.";
    }
    if (form.button_text_en === "") {
      textButtonError = "Text button (EN) is required.";
    }

    if (titleError || imageError || descriptionError || textButtonError || startDateError) {
      setValidationError({
        titleError,
        imageError,
        descriptionError,
        textButtonError,
        startDateError,
      });
      return false;
    } else {
      setValidationError({
        titleError,
        imageError,
        descriptionError,
        textButtonError,
        startDateError,
      });
      return true;
    }
  }

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setSaving(true);
    try {
      let formData = new FormData();
      formData.append("title", form["title"]);
      formData.append("description", form["description"]);
      formData.append("button_text", form["button_text"]);
      formData.append("start_date", form["start_date"]);
      formData.append("media", form.image);
      formData.append("item_id", form.item);
      formData.append("title_en", form.title_en);
      formData.append("description_en", form.description_en);
      formData.append("button_text_en", form.button_text_en);
      let res = await addModalPromo(formData);
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setSaving(false);
        setTimeout(() => {
          getItemDetail();
        }, 1200);
      } else {
        closeProcessingAlert();
        setSaving(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      setSaving(false);
      return false;
    }
  };

  const deletePromo = async () => {
    setRemove(true);
    try {
      let res = await deleteModalPromo();
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setTimeout(() => {
          setForm((old) => {
            return {
              ...old,
              title: "",
              previewImage: "./images/noimage.png",
              image: "",
              item: "",
              description: "",
              button_text: "",
              start_date: "",
              title_en: "",
              description_en: "",
              button_text_en: "",
            };
          });
          setPromo((old) => {
            return {
              ...old,
              title: "",
              previewImage: "./images/noimage.png",
              image: "",
              item: "",
              description: "",
              button_text: "",
              start_date: "",
              title_en: "",
              description_en: "",
              button_text_en: "",
            };
          });
          setItem((old) => {
            return {
              ...old,
              title: "",
            };
          });
          setRemove(false);
        }, 1200);
      } else {
        closeProcessingAlert();
        setRemove(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      setRemove(false);
      return false;
    }
  };

  const editPromo = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setSaving(true);
    try {
      let formData = new FormData();
      formData.append("title", form.title);
      formData.append("description", form.description);
      formData.append("button_text", form.button_text);
      formData.append("start_date", form.start_date);
      formData.append("media", form.image);
      formData.append("item_id", form.item);
      formData.append("title_en", form.title_en);
      formData.append("description_en", form.description_en);
      formData.append("button_text_en", form.button_text_en);
      let res = await editModalPromo(formData);
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setSaving(false);
        setTimeout(() => {
          getItemDetail();
        }, 1200);
      } else {
        closeProcessingAlert();
        setSaving(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      setSaving(false);
      return false;
    }
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Add Promo</h4>
                    </div>
                    <div className="col mt-20 mb-50">
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Title</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="title"
                                value={form.title}
                                onChange={inputHandler}
                                placeholder="Title"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.titleError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Description</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="description"
                                value={form.description}
                                onChange={inputHandler}
                                placeholder="Description"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Title (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="title_en"
                                value={form.title_en}
                                onChange={inputHandler}
                                placeholder="Title (EN)"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.titleError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Description (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="description_en"
                                value={form.description_en}
                                onChange={inputHandler}
                                placeholder="Description (EN)"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Text button</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="button_text"
                                value={form.button_text}
                                onChange={inputHandler}
                                placeholder="Text button"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.textButtonError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Text button</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="button_text_en"
                                value={form.button_text_en}
                                onChange={inputHandler}
                                placeholder="Text button (EN)"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.textButtonError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Start date</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="date"
                                autoComplete="off"
                                name="start_date"
                                value={form.start_date}
                                onChange={inputHandler}
                                placeholder="Start date"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.startDateError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Image</label>
                            <div className="col-md-12">
                              {(!isDisabled || promo.button_text === "") && <input className="form-control mb-2" type="file" name="image" onChange={onImageChange} />}
                              <img alt="" src={form.previewImage} width="100px" height="100px"></img>
                            </div>
                            <span className="validationErr">{validationError.imageError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Item</label>
                            {!isDisabled || promo.button_text === "" ? (
                              <Select
                                name="item"
                                options={itemList}
                                formatOptionLabel={(item) => (
                                  <div>
                                    <img width="20px" height="20px" src={item.image} alt="" />
                                    <span>{item.label}</span>
                                  </div>
                                )}
                                onChange={inputHandler}
                                styles={{ container: (base) => ({ ...base, marginBottom: "20px" }) }}
                              />
                            ) : null}
                            <div className="col-md-12">
                              {promo.button_text !== "" && promo.item !== "" ? (
                                <div>
                                  <img width="60px" height="60px" src={item.image} alt="" />
                                  <span className="m-2">{item.label}</span>
                                </div>
                              ) : (
                                <span>No item selected</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="text-center">
                        <br />
                        {!remove && promo.button_text !== "" ? (
                          <button className="btn btn-primary mx-2 w-100" onClick={deletePromo}>
                            Delete
                          </button>
                        ) : remove ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" disabled>
                            Please wait <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : null}
                        {!saving && promo.button_text === "" ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={submitForm}>
                            Save
                          </button>
                        ) : saving ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" disabled>
                            Please wait <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : !isDisabled ? (
                          <button type="submit" className="btn btn-primary mx-2 w-150" onClick={editPromo}>
                            Save new modal
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={toggleEditMode}>
                            Edit
                          </button>
                        )}
                        {!isDisabled ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={toggleEditMode}>
                            Cancel
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default PromoModal;
