import React, { useEffect, useMemo, useState } from "react";

const MultiFilePicker = ({ onUpdate, onUpdateLable, onRemove, files, title }) => {
  console.log(files);
  const handleFileChange = (e, index) => {
    const filesUpdated = [...files];
    filesUpdated[index].file = e.target.files[0];
    onUpdate(filesUpdated);
  };

  const handleTextChange = (e, index) => {
    const filesUpdated = [...files];
    filesUpdated[index].title = e.target.value;
    if (filesUpdated[index].id) {
      onUpdateLable(filesUpdated[index].id, e.target.value);
    } else {
      onUpdate(filesUpdated);
    }
  };

  const removeFile = (index) => {
    onRemove(files[index].id);
  };

  const addFile = () => {
    onUpdate([...files, {}]);
  };

  return (
    <div /* className="d-flex justify-content-center flex-column align-items-center" */>
      <h3>{title}</h3>
      {files &&
        files.map((file, index) => (
          <div key={index} style={{ marginTop: "50px" }}>
            <h4 style={{ marginBottom: "10px" }} className="my-3">
              PDF #{index + 1}
            </h4>
            <hr className="hr" />
            {file.link ? (
              <a className=" btn btn-primary" rel="noreferrer" target="_blank" href={file.link}>
                Go to pdf
              </a>
            ) : (
              <input className="form-control" type="file" key={index} onChange={(e) => handleFileChange(e, index)} />
            )}
            <label className="mt-3 col-form-label d-block">File name</label>
            <input className="form-control mb-3" type="text" key={index} value={file.title} onChange={(e) => handleTextChange(e, index)} />
            <button className="btn btn-danger" onClick={() => removeFile(index)}>
              Remove
            </button>
          </div>
        ))}
      <button className="btn btn-primary mt-4" onClick={() => addFile()}>
        Add
      </button>
    </div>
  );
};

export default MultiFilePicker;
