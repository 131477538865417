import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import moment from "moment";
import $ from "jquery";
import { getCategoryListAction, getItemDetailAction, editItemAction, getAttributebyidListAction, getexpertlistAction } from "../Action/action";

const ItemUpdate = () => {
  const [form, setForm] = useState({
    category_id: "",
    expert_id: "",
    name: "",
    lable: "",
    lable_en: "",
    item_put_on: "",
    image: "",
    previewImage: "./images/noimage.png",
    description: "",
    description_en: "",
    share_quantity: "",
    last_month_price: "",
    share_price: "",
    tag: "",
    tagCustom: "",
    tagCustom_en: "",
    sell_end_date: "",
    last_year_perf: "",
    market_value: "",
    market_price: 0,
    gallery: [{ id: Date.now(), image: null, previewImage: "./images/noimage.png" }],
    last_performance_value: 0,
    last_performance_period: "one_year",
    chart: "",
    chartImagePreview: "",
    order: 0,
    pdf: "",
    pdfPreview: "",
    pdfTitle: "",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [validatioError, setvalidatioError] = useState({});
  const [attributesList, setAttributesList] = useState([]);
  const [expertlist, setexpertlist] = useState([]);

  useEffect(() => {
    getcategory();
    getItemDetail();
    getexpert();
  }, []);

  const onChartImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, chart: img, chartImagePreview: URL.createObjectURL(img) };
      });
    }
  };

  const onPdfChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let pdf = event.target.files[0];
      setForm((old) => {
        return { ...old, pdf: pdf, pdfPreview: "" };
      });
    }
  };

  const getexpert = async () => {
    let res = await getexpertlistAction();
    if (res.success) {
      setexpertlist(res.data);
    }
  };

  const getcategory = async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setCategoryList(res.data);
    }
  };

  const getattributesByid = async (id) => {
    let res = await getAttributebyidListAction({ category_id: id });
    if (res.success) {
      setAttributesList(res.data);
    }
  };
  const getItemDetail = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getItemDetailAction({ item_id: id });
    if (res.success) {
      let data = res.data;
      if (data.attributes.length === 0) {
        getattributesByid(data.category_id);
      } else {
        setAttributesList(data.attributes);
        getattributesByid(data.category_id);
      }
      let sell_end_date = moment(data.sell_end_date).format("YYYY-MM-DD");
      setForm((old) => {
        return {
          ...old,
          item_id: id,
          category_id: data.category_id,
          expert_id: data.expert_id,
          name: data.name,
          lable: data.lable,
          lable_en: data.lable_en,
          previewImage: config.apiUrl + "/uploads/" + data.image,
          description: data.description,
          description_en: data.description_en,
          share_quantity: data.share_quantity,
          last_month_price: data.last_month_price,
          last_month_per: data.last_month_per,
          share_price: data.share_price,
          tag: data.tag,
          tagCustom: data.tagCustom,
          tagCustom_en: data.tagCustom_en,
          item_put_on: data.item_put_on === 1 ? "Pre-Order" : "Marketplace",
          sell_end_date: sell_end_date,
          last_year_perf: data.last_year_perf,
          market_value: data.market_value,
          market_price: data.market_price,
          gallery: data.gallery,
          chartImagePreview: data.chart !== "" ? config.apiUrl + "/uploads/" + data.chart : null,
          order: data.order,
          last_performance_value: data.last_performance_value,
          last_performance_period: data.last_performance_period,
          pdfPreview: data.pdfPreview?.file ? config.apiUrl + "/itemPdf/" + data.pdfPreview.file : null,
          pdfTitle: data.pdfPreview?.title,
        };
      });
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
    if (e.target.name === "category_id") {
      const iid = window.location.href.split("/").pop();
      let res = await getItemDetailAction({ item_id: iid });
      let data = res.data;
      if (value === data.category_id) {
        getItemDetail();
      } else {
        getattributesByid(e.target.value);
      }
    }
  };

  function validate() {
    let category_nameError = "";
    if (form.category === "") {
      category_nameError = "Category is required.";
    }

    if (category_nameError) {
      setvalidatioError({
        category_nameError,
      });
      return false;
    } else {
      return true;
    }
  }

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    // Collect attribute data
    const atData = $(".attr")
      .map(function () {
        return {
          attribute_id: $(this).data("attr_id"),
          value: $(this).val(),
        };
      })
      .get();

    form.attribute_data = JSON.stringify(atData);
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (key !== "gallery") formData.append(key, value);
    });

    let galleryIds = form.gallery.map((g) => g.id);

    const hasNewImages = form.gallery.some((g) => g.image !== null);
    if (hasNewImages) {
      form.gallery.forEach((g) => {
        if (g.image !== null) {
          formData.append("gallery", g.image);
        } else if (g.toBeDeleted) {
          galleryIds = galleryIds.filter((id) => id !== g.id);
        }
      });
    }

    if (galleryIds.length > 0) {
      formData.append("galleryData", JSON.stringify(galleryIds));
    }

    try {
      const res = await editItemAction(formData);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => (window.location.href = `${config.baseUrl}itemList`), 1200);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const onImageGalleryChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = { ...gallery[index], image: img, previewImage: URL.createObjectURL(img) };
      setForm((old) => ({ ...old, gallery }));
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Update Item</h4>
                      <a className="text-primary" href={`${config.baseUrl}itemList`}>
                        <i className="fa fa-arrow-left"></i> Back
                      </a>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Category</label>
                            <div className="col-md-12">
                              <select className="form-control" name="category_id" value={form.category_id} onChange={inputHandler}>
                                <option value="">-Select Category-</option>
                                {categoryList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.category_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Expert Name</label>
                            <div className="col-md-12">
                              <select className="form-control" name="expert_id" value={form.expert_id} onChange={inputHandler1}>
                                <option value="">-Select Expert Name-</option>
                                {expertlist.map((item) => (
                                  <option value={item.id}>{item.expert_name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Name</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="name" value={form.name} onChange={inputHandler} placeholder="Item Name" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Order</label>
                            <div className="col-md-12">
                              <input className="form-control" type="number" autoComplete="off" name="order" value={form.order} onChange={inputHandler} placeholder="order" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      {form.gallery.map((val, key) => (
                        <div className="row" key={key}>
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Image</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="image" onChange={(e) => onImageGalleryChange(e, key)} />
                                {form.gallery[key].previewImage == null ? (
                                  <img alt="" src={config.imageUrl + form.gallery[key].image_path ?? ""} width="100px" height="100px"></img>
                                ) : (
                                  <img alt="" src={form.gallery[key].previewImage ?? ""} width="100px" height="100px"></img>
                                )}
                              </div>
                              <span className="validationErr">{validatioError.imageError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <button
                              style={{ maxWidth: "fit-content" }}
                              className="btn btn-danger"
                              onClick={() => {
                                let gallery = form.gallery;
                                gallery = gallery.filter((el) => el !== form.gallery[key]);
                                setForm({
                                  ...form,
                                  gallery: gallery,
                                });
                              }}
                            >
                              Remove image
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        style={{ maxWidth: "fit-content", margin: "0 auto" }}
                        className="btn btn-primary"
                        onClick={() =>
                          setForm({
                            ...form,
                            gallery: [
                              ...form.gallery,
                              {
                                previewImage: "./images/noimage.png",
                                image: "",
                              },
                            ],
                          })
                        }
                      >
                        Add image
                      </button>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Chart image</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="image" onChange={onChartImageChange} />
                              {form.chartImagePreview && <img alt="" src={form.chartImagePreview} width="100px" height="100px"></img>}
                            </div>
                            <span className="validationErr">{validatioError.imageError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Fact sheet (pdf)</label>
                            <div className="col-md-12">
                              <input className="form-control mb-2" type="file" name="pdf" onChange={onPdfChange} />
                              {form.pdfPreview && (
                                <a className=" btn btn-primary" rel="noreferrer" target="_blank" href={form.pdfPreview}>
                                  Go to pdf
                                </a>
                              )}
                              <label className="col-form-label col-md-12">Fact sheet title</label>
                              <input
                                className="form-control mb-2"
                                type="text"
                                name="pdfTitle"
                                value={form.pdfTitle}
                                onChange={(e) => setForm({ ...form, pdfTitle: e.target.value })}
                              />
                              
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="lable" value={form.lable} onChange={inputHandler} placeholder="Lable" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Lable (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="lable_en"
                                value={form.lable_en}
                                onChange={inputHandler}
                                placeholder="Lable (EN)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />
                        <div className="col-md-4" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Tag</label>
                            <div className="col-md-12">
                              <select className="form-control" name="tag" value={form.tag} onChange={inputHandler}>
                                <option value={0}>Custom Tag</option>
                                <option value={1}>Best Seller</option>
                                <option value={2} selected>
                                  New Item
                                </option>
                                <option value={3}>Holding Period 1 year</option>
                                <option value={4}>Holding Period 2 years</option>
                                <option value={5}>Holding Period 3 years</option>
                                <option value={6}>Holding Period 4 years</option>
                                <option value={7}>Holding Period 5 years</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      {(form.tag !== 0 || form.tag !== "0") && (
                        <>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom"
                                    value={form.tagCustom && form.tagCustom !== "undefined" ? form.tagCustom : ""}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                          <div className="row">
                            <div className="col-md-6" />
                            <div className="col-md-4">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Custom Tag (EN)</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    autoComplete="off"
                                    name="tagCustom_en"
                                    value={form.tagCustom_en && form.tagCustom_en !== "undefined" ? form.tagCustom_en : ""}
                                    onChange={inputHandler}
                                    placeholder="Custom Tag (EN)"
                                  />
                                </div>
                              </div>
                              <span className="validationErr">{validatioError.tagError}</span>
                            </div>
                            <div className="col-md-2" />
                          </div>
                        </>
                      )}
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">ItemPutOn</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" autoComplete="off" name="lable" value={form.item_put_on} placeholder="Lable" readOnly />
                              {/* <select className="form-control" name="item_put_on" value={form.item_put_on} onChange={inputHandler}>
																<option value={1}>Pre-order</option>
																<option value={2}>Marketplace</option>
															</select> */}
                            </div>
                          </div>
                        </div>

                        {/* {form.item_put_on == 1 ? (<> */}
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Sell End Date</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="date"
                                autoComplete="off"
                                name="sell_end_date"
                                value={form.sell_end_date}
                                onChange={inputHandler}
                                placeholder="Sell end date"
                              />
                            </div>
                          </div>
                        </div>
                        {/* </>) : ""} */}
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description (IT)</label>
                            <div className="col-md-12">
                              <textarea className="form-control" value={form.description} name="description" onChange={inputHandler} placeholder="Description"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description (EN)</label>
                            <div className="col-md-12">
                              <textarea className="form-control" value={form.description_en} name="description_en" onChange={inputHandler} placeholder="Description"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Month Price</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="last_month_price"
                                value={form.last_month_price}
                                onChange={inputHandler}
                                placeholder="Last month price."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Month Variation (%)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="last_month_per"
                                value={form.last_month_per}
                                onChange={inputHandler}
                                placeholder="Last month variation (%)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      {/* <div className="row">
												<div className="col-md-2" />
												<div className="col-md-4">
													<div className="form-group row mb-1">
														<label className="col-form-label col-md-12">
															Share Quantity
														</label>
														<div className="col-md-12">
															<input
																className="form-control"
																type="text" autoComplete="off"
																name="share_quantity"
																value={form.share_quantity}
																onChange={inputHandler}
																placeholder="Share Quantity"
															/>
														</div>
														
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group row mb-1">
														<label className="col-form-label col-md-12">
															Share Price
														</label>
														<div className="col-md-12">
															<input
																className="form-control"
																type="text" autoComplete="off"
																name="share_price"
																value={form.share_price}
																onChange={inputHandler}
																placeholder="Share Price"
															/>
														</div>
														
													</div>
												</div>
												<div className="col-md-2" />
											</div> */}

                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. Value %</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="last_performance_value"
                                value={form.last_performance_value}
                                onChange={inputHandler}
                                placeholder="Last perf."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Last Perf. period</label>
                            <div className="col-md-12">
                              <select className="form-control" name="last_performance_period" value={form.last_performance_period} onChange={inputHandler}>
                                <option value="">Select Period</option>
                                <option value="one_month">1 Month</option>
                                <option value="two_month">2 Months</option>
                                <option value="three_month">3 Months</option>
                                <option value="six_month">6 Months</option>
                                <option value="one_year">1 Year</option>
                                <option value="two_year">2 Years</option>
                                <option value="three_year">3 Years</option>
                                <option value="four_year">4 Years</option>
                                <option value="five_year">5 Years</option>
                                <option value="six_year">6 Years</option>
                                <option value="seven_year">7 Years</option>
                                <option value="eight_year">8 Years</option>
                                <option value="nine_year">9 Years</option>
                                <option value="ten_year">10 Years</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Market Value</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="market_value"
                                value={form.market_value}
                                onChange={inputHandler}
                                placeholder="Market value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Real Market Price</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                name="market_price"
                                value={form.market_price}
                                onChange={inputHandler}
                                placeholder="Market price"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      
                      <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            {attributesList.map((item, index) => (
                              <>
                                <label className="col-form-label col-md-12">{item.attribute_name}</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control attr"
                                    key={index}
                                    type="text"
                                    autoComplete="off"
                                    data-attr_id={item.id}
                                    // onChange={(e)=>inputHandler(e.target.value)}
                                    placeholder={`Enter ${item.attribute_name}`}
                                    value={item.value}
                                    onKeyPress={(event) => {
                                      /*  if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } */
                                    }}
                                  />
                                </div>
                                <span className="validationErr">{validatioError.brandError}</span>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <br />
                        <button type="submit" className="btn btn-primary" onClick={submitForm}>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default ItemUpdate;
