import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import config from "../coreFIles/config";

const UserTransactionsTable = ({ transactions, isLoading, isError, isRefetching }) => {
  const table = useMaterialReactTable({
    columns: [
      {
        accessorKey: "id",
        header: "Transaction ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "item.name",
        header: "Item Name",
        enableSorting: false,
      },
      {
        accessorKey: "item.category.category_name",
        header: "Category",
        enableSorting: false,
      },
      {
        accessorKey: "price",
        header: "Price",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "share_qty",
        header: "Quantity",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "stripe_amount",
        header: "Stripe Amount",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.amount * 1.025 - row.bonus_liquidity_used - row.user_liquidity_used - row.bank_liquidity_used < 0.1 ? 0 : (row.amount * 1.025 - row.bonus_liquidity_used - row.user_liquidity_used - row.bank_liquidity_used).toFixed(2),
      },

      {
        accessorKey: "bonus_liquidity_used",
        header: "Bonus Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.bonus_liquidity_used.toFixed(2),
      },
      {
        accessorKey: "user_liquidity_used",
        header: "User Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.user_liquidity_used.toFixed(2),
      },
      {
        accessorKey: "bank_liquidity_used",
        header: "Bank Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.bank_liquidity_used,
      },
      {
        accessorKey: "trx_type_id",
        header: "Transaction Type",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Buy", value: 1 },
          { text: "Sell", value: 2 },
        ],
        accessorFn: (row) => (row.trx_type_id === 1 ? "Buy" : "Sell"),
      },
      {
        accessorKey: "market_type",
        header: "Market Type",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "PRIMARY", value: "PRIMARY" },
          { text: "SECONDARY", value: "SECONDARY" },
        ],
      },
      {
        accessorKey: "payment_method",
        header: "Payment Method",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "CARD", value: "CARD" },
          { text: "ALL_LIQUIDITY", value: "ALL_LIQUIDITY" },
          { text: "ADMIN_BUYBACK", value: "ADMIN_BUYBACK" },
        ],
      },
      {
        accessorKey: "stripe_trx_id",
        header: "Stripe Transaction ID",
        enableSorting: false,
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableSorting: false,
        filterVariant: "date",
        accessorFn: (row) => moment(row.datetime).format("DD-MM-YYYY HH:mm:ss"),
      },
    ],
    data: transactions || [],
    enableRowSelection: false,
    //    enableStickyHeader: true,
    getRowId: (row) => row.id,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      sorting: [{ id: "datetime", desc: true }],
    },

    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f", //add a border between columns
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f", //add a border between columns
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default UserTransactionsTable;
