import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { deleteAlertRequestAction, getAlertRequestListAction } from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";

const Alert_Request = () => {
  const [getAlertRequestlist, setAlertRequestList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAlert_Request();
  }, []);

  const getAlert_Request = async () => {
    let res = await getAlertRequestListAction();
    if (res.success) {
      setAlertRequestList(res.data);
    }
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = searchTerm
    ? getAlertRequestlist.filter(item =>
      item.item_name && item.item_name.toLowerCase().includes(searchTerm))
    : getAlertRequestlist;

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      Hide: true,
      cell: (item) => {
        return `${item.name}`;
      }
    },
    {
      key: "email",
      text: "Email",
      cell: (item) => {
        return `${item.email ? item.email : "-"}`;
      },
    },
    {
      key: "number",
      text: "Number",
      cell: (item) => {
        return `${item.email ? item.country_code + " " + item.mobile_no : "-"}`;
      }
    },
    {
      key: "item",
      text: "Item",
      cell: (item) => {
        return `${item.item_name ? item.item_name : "-"}`;
      },
    },
    {
      key: "date",
      text: "Request Date",
      cell: (item) => {
        return `${moment(item.request_date).format("YYYY-MM-DD")}`;
      },
    },

    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-sm btn-default"
              id="editbtnid"
              onClick={() => deletealertrequest(item.id)}
            >
              {" "}
              Delete
            </button>{" "}
            &nbsp;
          </>
        );
      },
    },
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: false,
    show_pagination: true,
    pagination: "advance",
    button: {
      csv: true,
    }
  };

  const deletealertrequest = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Alert Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteAlertRequestAction({ id: id });
        if (res.success) {
          getAlert_Request();

          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");

        }
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">


            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Alert Request List</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                    </div>
                    <div className="box-body">
                      <div className="d-flex justify-content-end mb-3">
                        <input
                          type="search"
                          className="form-control"
                          style={{ maxWidth: '250px' }}
                          placeholder="Search by Item Name..."
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </div>

                      <ReactDatatable
                        config={configForTable}
                        records={filteredData}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Alert_Request;
