import React, { useEffect, useMemo, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { editCategoryAction, getCategoryDetailAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import MultiFilePicker from "../components/MultiFilePicker";

const Categoryupdate = () => {
  const [form, setForm] = useState({ id: "", category_name: "", description: "", order: 0, files: [], fileIdsToRemove: [], labelsToUpdate: [] });
  useEffect(() => {
    getCategoryid();
  }, []);

  const getCategoryid = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getCategoryDetailAction({ id: id });
    if (res.success) {
      let data = res.data;
      setForm((old) => {
        let filesData = data.item_pdfs.map((file) => {
          return { id: file.id, title: file.title, link: config.apiUrl + "/itemPdf/" + file.file };
        });
        return { ...old, id: id, category_name: data.category_name, description: data.description, order: data.order, orderSecondary: data.order_secondary, files: filesData };
      });
    }
  };
  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    //return
    const formData = new FormData();
    formData.append("id", form.id);
    formData.append("category_name", form.category_name);
    formData.append("description", form.description);
    formData.append("order", form.order);
    formData.append("order_secondary", form.orderSecondary);
    if (form.fileIdsToRemove.length > 0) {
      formData.append("fileIdsToRemove", JSON.stringify(form.fileIdsToRemove));
    }
    if (form.labelsToUpdate.length > 0) {
      formData.append("labelsToUpdate", JSON.stringify(form.labelsToUpdate));
    }

    form.files.forEach((file, index) => {
      if (!file.id) {
        formData.append(`files`, file.file);
        formData.append(`file_titles`, file.title);
      }
    });

    let res = await editCategoryAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}Category`;
      }, 1200);
    } else {
      toast.error(res.msg);
    }
  };

  const handleFileChange = (filesUpdated) => {
    console.log(filesUpdated);
    setForm((old) => {
      return { ...old, files: filesUpdated };
    });
  };

  const handleFileRemove = (id) => {
    console.log(id);
    setForm((old) => {
      return { ...old, files: old.files.filter((file) => file.id !== id), fileIdsToRemove: [...old.fileIdsToRemove, id] };
    });
  };

  const handleFileLableChange = (id, value) => {
    let labelsToUpdate = form.labelsToUpdate;
    if (labelsToUpdate.find((label) => label.id === id)) {
      labelsToUpdate = labelsToUpdate.filter((label) => label.id !== id);
    }
    labelsToUpdate.push({ id, value: value });
    setForm((old) => {
      return { ...old, labelsToUpdate: labelsToUpdate };
    });
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Category</h4>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>

                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Category Name</label>
                            <div className="col-md-12">
                              <input className="form-control" type="text" name="category_name" value={form.category_name} onChange={inputHandler} placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Short Description</label>
                            <div className="col-md-12">
                              <textarea value={form.description} className="form-control" name="description" onChange={inputHandler} placeholder="Description"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-2"></div>

                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Order</label>
                            <div className="col-md-12">
                              <input className="form-control" type="number" name="order" value={form.order} onChange={inputHandler} placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row">
                        <div className="col-md-2"></div>

                        <div className="col-md-8">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Order secondary market</label>
                            <div className="col-md-12">
                              <input className="form-control" type="number" name="orderSecondary" value={form.orderSecondary} onChange={inputHandler} placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="col-md-8 offset-md-2">
                        <div className="row  mb-1 mt-3">
                          <div className="col-md-12 p-10">
                            <MultiFilePicker
                              onUpdate={handleFileChange}
                              onUpdateLable={handleFileLableChange}
                              onRemove={handleFileRemove}
                              files={form.files}
                              title="Upload Files"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <br />
                        <button type="submit" className="btn btn-primary" onClick={submitForm}>
                          Save Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Categoryupdate;
