import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import config from "../coreFIles/config";

const UserWithdrawalsTable = ({ withdrawals, isLoading, isError, isRefetching }) => {
  const table = useMaterialReactTable({
    columns: [
      {
        accessorKey: "id",
        header: "ID",
        size: 20
      },
      {
        accessorKey: "type",
        header: "Type",
        size: 50
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 30,
        Cell: ({ cell }) => {
          const amount = cell.getValue();
          return amount ? `${amount}` : '-';
        },
      },
      {
        accessorKey: "iban_paypal_upi_id",
        header: "IBAN/PayPal/UPI ID",
        size: 100,
      },
      {
        accessorKey: "account_holder",
        header: "Account Holder",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 50,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          switch (status) {
            case 1:
              return <span className="badge bg-success">Approved</span>;
            case 2:
              return <span className="badge bg-danger">Rejected</span>;
            case 0:
            default:
              return <span className="badge bg-warning">Pending</span>;
          }
        },
      },
      {
        accessorKey: "datetime",
        header: "Date",
        Cell: ({ cell }) => moment(cell.getValue()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ],
    data: withdrawals || [],
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      sorting: [{ id: 'datetime', desc: true }],
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0
    },
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default UserWithdrawalsTable;