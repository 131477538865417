import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  getexpertlistAction,
  addexpertAction,
  editexpertAction,
  deleteexpertAction,
} from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";

const ExpertDetails = () => {
  const [getexpertDetailslist, setExpertDetailsList] = useState({});
  const [userDetails, setuserDetails] = useState({});
  const [adduserDetails, setadduserDetails] = useState({'expert_name':"", "description":"", "description_en":""});
  const [image_preview, setimage_preview] = useState("images/noimage.png");
  const [image_preview1, setimage_preview1] = useState("");

  useEffect(() => {
    getexpertDetails();
  }, []);

  const partnerPic = async (e) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    // setimage_file(image_as_files);
    setimage_preview(image_as_base64);
    setadduserDetails((old) => {
      return { ...old, ["expert_image"]: image_as_files };
    });
  };

  const partnerPic1 = async (e) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    // setimage_file1(image_as_files);
    setimage_preview1(image_as_base64);
    setuserDetails((old) => {
      return { ...old, ["new_expert_image"]: image_as_files };
    });
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setadduserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const getexpertDetails = async () => {
    let res = await getexpertlistAction();
    if (res.success) {
      setExpertDetailsList(res.data);
    }
  };

  function editPartner(item) {
    setuserDetails(item);
  }

  const columns = [
    {
      key: "Sno.",
      text: "Sno.",
      cell: (row, index) => index + 1,
    },

    {
      key: "expert_image",
      text: "Expert Image",
      cell: (item) => {
        return (
          <>
            <a target="__blank" href={`${config.imageUrl}` + item.expert_image}>
              <img
                src={`${config.imageUrl}` + item.expert_image}
                width="70px"
                height="70px" alt=""
              ></img>
            </a>
            <br />
          </>
        );
      },
    },
    {
      key: "expert_name",
      text: "Expert Name",
      cell: (item) => {
        return `${item.expert_name}`;
      },
    },
    {
      key: "description",
      text: "Description",
      cell: (item) => {
        return `${item.description == null ? "" : item.description}`;
      },
    },
    {
      key: "description_en",
      text: "Description (EN)",
      cell: (item) => {
        return `${item.description_en == null ? "" : item.description_en}`;
      },
    },
    {
      key: "date",
      text: "Date",
      cell: (item) => {
        return `${moment(item.datetime).format("YYYY-MM-DD")}`;
      },
    },
    {
      key: "action",
      text: "Action",
      cell: (item) => {
        return (
          <>
            <button
              type="button"
              onClick={() => editPartner(item)}
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              name={item.id}
              value={item.id}
            >
              Edit
            </button>
            <br />
            <br />
            <button
              type="button"
              onClick={() => DeleteUserGuide(item)}
              className="btn btn-primary btn-sm"
              name={item.id}
              value={item.id}
            >
              Delete
            </button>
          </>
        );
      },
    },
    
  ];

  const configForTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advance",
    button: {
      excel: false,
      print: false,
    },
  };

  const updatecontent = async (e) => {
    e.preventDefault();
    console.log("userDetails", userDetails);
    let res = await editexpertAction(userDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName("closeModal")[0].click();
      getexpertDetails();
      // setTimeout(() => {
      //   window.location.href = `${config.baseUrl}userguide`;
      // }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  const addExpert = async (e) => {
    e.preventDefault();
    console.log("adduserDetails", adduserDetails);
    if(!adduserDetails.expert_name || adduserDetails.expert_name == ""){
      toast.error('Expert name required!');
      return;
    }
    else if(!adduserDetails.description || adduserDetails.description == ""){
      toast.error('Expert description required!');
      return;
    }

    else if(!adduserDetails.description_en || adduserDetails.description_en == ""){
      toast.error('Expert description required (EN)!');
      return;
    }

    let res = await addexpertAction(adduserDetails);
    if (res.success) {
      toast.success(res.msg);
      // document.getElementsByClassName("closeModal1")[0].click();
      // getexpertDetails();
      // setadduserDetails({'expert_name':"", "description":"", 'expert_image':""});
      // setimage_preview('images/noimage.png')
      setTimeout(() => {
        window.location.href = `${config.baseUrl}expertdetails`;
      }, 1000);
    } else {
      toast.error(res.msg);
    }
  };

  const DeleteUserGuide = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Assistant Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteexpertAction({ id: id });
        if (res.success) {
          getexpertDetails();

          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Expert List</h4>
                      {/* <a
                        href={`${config.baseUrl}itemAdd `}
                        className="btn btn-sm btn-primary add_btn"
                      >
                        Add New
                      </a> */}
                      <button
                        type="button"
                        // onClick={() => editPartner(item)}
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                      >
                        Add
                      </button>
                    </div>
                    <div className="box-body">
                      <ReactDatatable
                        config={configForTable}
                        records={getexpertDetailslist}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Edit Modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        description
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={updatecontent}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Expert Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter Title"
                              onChange={inputHandler}
                              name="expert_name"
                              value={userDetails.expert_name}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              onChange={inputHandler}
                              name="description"
                              value={userDetails.description}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea2"
                              className="form-label"
                            >
                              Description (EN)
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea2"
                              rows={3}
                              onChange={inputHandler}
                              name="description_en"
                              value={userDetails.description_en}
                            />
                          </div>

                          <div className="mb-3">
                            {image_preview1 === "" ? (
                              userDetails?.new_expert_image === null ||
                                userDetails?.new_expert_image === "null" ||
                                userDetails?.new_expert_image === "" ? (
                                ""
                              ) : (
                                <img
                                  style={{ height: "100px", width: "100px" }}
                                  className="object-cover w-full h-32"
                                  src={`${config.imageUrl}${userDetails?.new_expert_image}`}
                                  alt=""
                                />
                              )
                            ) : (
                              <img
                                style={{ height: "100px", width: "100px" }}
                                id="image"
                                className="object-cover w-full h-32"
                                src={image_preview1} alt=""
                              />
                            )}

                            <input
                              name="new_expert_image"
                              onChange={partnerPic1}
                              id="fileInput"
                              accept="image/*"
                              className="form-control mt-5"
                              type="file"
                            />
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" className="btn btn-primary">
                              submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Edit Modal Ends */}
              {/*Add Modal */}
              <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Expert Details
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <form onSubmit={addExpert}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                               Expert Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter expert_name"
                              onChange={inputHandler1}
                              name="expert_name"
                              value={adduserDetails.expert_name}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                               Description
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              onChange={inputHandler1}
                              placeholder="Enter description"
                              name="description"
                              value={adduserDetails.description}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                               Description (EN)
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              onChange={inputHandler1}
                              placeholder="Enter description (EN)"
                              name="description_en"
                              value={adduserDetails.description_en}
                            />
                          </div>

                          <div className="mb-3">
                            {image_preview === "" ? (
                              adduserDetails?.expert_image === null ||
                                adduserDetails?.expert_image === "null" ||
                                adduserDetails?.expert_image === "" ? (
                                <img
                                  style={{ height: "100px", width: "100px" }}
                                  className="object-cover w-full h-32"
                                  src=""
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ height: "100px", width: "100px" }}
                                  className="object-cover w-full h-32"
                                  src={`${config.imageUrl}${adduserDetails?.expert_image}`}
                                  alt=""
                                />
                              )
                            ) : (
                              <img
                                style={{ height: "100px", width: "100px" }}
                                id="image"
                                className="object-cover w-full h-32"
                                src={image_preview} alt=""
                              />
                            )}

                            <input
                              name="expert_image"
                              onChange={partnerPic}
                              id="fileInput"
                              accept="image/*"
                              className="form-control mt-5"
                              type="file"
                            />
                          </div>

                          <div className="modal-footer mt-20">
                            <button type="submit" className="btn btn-primary">
                              submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary closeModal1"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*Add Modal Ends */}
            </section>
            {/* /.content */}
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default ExpertDetails;
